<template>
  <div>
    <v-card elevation="2" class="mb-8">
      <v-card-subtitle>Downloads</v-card-subtitle>
      <v-divider></v-divider>
      <v-data-table
        :headers="sizesTable.headers"
        :items="downloadsWithIndex"
        :disable-pagination="true"
        :hide-default-footer="true"
        no-data-text="There are currently no files"
      >
        <template v-slot:foot>
          <div class="pl-4 pr-4">
            <v-btn color="accent" @click="openVarietyDialog()" small
              >Add New File</v-btn
            >
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                text
                icon
                color="blue"
                v-on="on"
                class="mr-2"
                @click="openVarietyDialog(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                text
                icon
                color="red"
                v-on="on"
                @click="deleteVariety(item.index)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-text class="pb-0"> </v-card-text>
    </v-card>

    <v-dialog
      v-model="downloadDialog.open"
      max-width="400"
      @click:outside="resetVarietyDialog()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ downloadDialog.isEditing ? "Edit" : "New" }} File
        </v-card-title>
        <v-card-text class="pt-6 pb-0">
          <v-text-field
            label="Name"
            v-model="downloadDialog.fields.name"
            outlined
          ></v-text-field>

          <v-input
            type="hidden"
            :value="downloadDialog.fields.id"
            outlined
          ></v-input>

          <v-file-input
            v-model="downloadDialog.fields.file"
            label="File Upload"
            outlined
            background-color="white"
            @change="fileHasChanged()"
          ></v-file-input>

          <v-input :value="downloadDialog.fields.changed" outlined></v-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetVarietyDialog()"
            >Close</v-btn
          >
          <v-btn color="accent" text type="submit" @click="saveVarietyDialog()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
    },
  },

  data() {
    return {
      fields: {
        downloads: [],
      },
      errors: {},
      sizesTable: {
        headers: [
          { text: "Name", value: "name", sortable: false },

          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "right",
          },
        ],
        dialog: {
          open: false,
          loading: false,
        },
      },
      downloadDialog: {
        open: false,
        isEditing: false,
        download: {},
        fields: {
          name: null,
          file: null,
          changed: false,
          id: null,
        },
      },
    };
  },

  mounted() {
    if (this.product == null) {
      this.fields.downloads = [];
    }
  },

  computed: {
    downloadsWithIndex() {
      return this.fields.downloads.map((items, index) => ({
        ...items,
        index: index,
      }));
    },
  },

  methods: {
    fileHasChanged() {
      this.downloadDialog.fields.changed = true;
    },

    openVarietyDialog(download = null) {
      if (download !== null) {
        this.downloadDialog.isEditing = true;
        this.downloadDialog.download = download;
        this.downloadDialog.fields.name = download.name;
        this.downloadDialog.fields.file = download.file;
        this.downloadDialog.fields.id = download.id;
      }

      this.downloadDialog.open = true;
    },

    resetVarietyDialog() {
      this.downloadDialog.open = false;
      this.downloadDialog.isEditing = false;
      this.downloadDialog.download = {};
      this.downloadDialog.fields.file = null;
      this.downloadDialog.fields.id = null;
      this.downloadDialog.fields.name = null;
      this.downloadDialog.fields.changed = false;
    },

    saveVarietyDialog() {
      if (this.downloadDialog.isEditing) {
        this.fields.downloads[this.downloadDialog.download.index].file =
          this.downloadDialog.fields.file;

        this.fields.downloads[this.downloadDialog.download.index].name =
          this.downloadDialog.fields.name;

        this.fields.downloads[this.downloadDialog.download.index].id =
          this.downloadDialog.fields.id;

        this.fields.downloads[this.downloadDialog.download.index].changed =
          this.downloadDialog.fields.changed;
      } else {
        this.fields.downloads.push({
          file: this.downloadDialog.fields.file,
          name: this.downloadDialog.fields.name,
          changed: this.downloadDialog.fields.changed,
        });
      }

      this.resetVarietyDialog();
    },

    deleteVariety(index) {
      this.fields.downloads.splice(index, 1);
    },

    saveForm() {
      return this.fields.downloads;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },
  },
};
</script>
