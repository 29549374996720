<template>
  <div>
    <v-card>
      <v-data-table
        :headers="varietiesTable.headers"
        :items="varietiesWithIndex"
        :disable-pagination="true"
        :hide-default-footer="true"
        no-data-text="There are no varieties"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="text-subtitle-1">Varieties</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="accent" small @click="openVarietyDialog()"
              >Add Row</v-btn
            >
          </v-toolbar>
          <v-alert
            type="error"
            dense
            col="4"
            rounded="0"
            class="mb-0"
            v-if="errors.hasOwnProperty('varieties')"
            >{{ errors["varieties"].join(", ") }}</v-alert
          >
          <v-divider></v-divider>
        </template>
        <template v-slot:item.variety="{ item }">
          <div style="white-space: pre-line" v-html="item.variety"></div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                text
                icon
                color="blue"
                v-on="on"
                class="mr-2"
                @click="openVarietyDialog(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                text
                icon
                color="red"
                v-on="on"
                @click="deleteVariety(item.index)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="varietiesDialog.open"
      max-width="400"
      @click:outside="resetVarietyDialog()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{
            varietiesDialog.isEditing ? "Edit" : "New"
          }}
          Breakdown</v-card-title
        >
        <v-card-text class="pt-6 pb-0">
          <v-text-field
            label="Variety"
            v-model="varietiesDialog.fields.variety"
            outlined
          ></v-text-field>
          <v-text-field
            label="Product *"
            v-model="varietiesDialog.fields.product"
            type="text"
            outlined
          ></v-text-field>
          <v-text-field
            label="Percentage *"
            v-model="varietiesDialog.fields.percent"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetVarietyDialog()"
            >Close</v-btn
          >
          <v-btn color="accent" text type="submit" @click="saveVarietyDialog()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      required: true,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
  },

  data() {
    return {
      fields: {
        breakdown: [],
      },
      errors: {},
      varietiesTable: {
        headers: [
          { text: "Variety", value: "variety", sortable: false },
          { text: "Product", value: "product", sortable: false },
          { text: "Percent", value: "percent", sortable: false },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "right",
          },
        ],
        dialog: {
          open: false,
          loading: false,
        },
      },
      varietiesDialog: {
        open: false,
        isEditing: false,
        variety: {},
        fields: {
          variety: "",
          product: null,
          percent: null,
        },
      },
    };
  },

  mounted() {
    if (this.product !== null) {
      if (this.product.details !== null) {
        this.fields.breakdown = this.product.details.breakdown;
      }
    }
  },

  computed: {
    varietiesWithIndex() {
      let varieties = this.fields.breakdown.map((variety, index) => ({
        variety: variety.variety != "null" ? variety.variety : "",
        percent: variety.percent,
        product: variety.product,
        index,
      }));

      return varieties;
    },
  },

  methods: {
    setErrors(errors = {}) {
      this.errors = errors;
    },

    openVarietyDialog(variety = null) {
      if (variety !== null) {
        this.varietiesDialog.isEditing = true;
        this.varietiesDialog.variety = variety;
        this.varietiesDialog.fields.variety = variety.variety;
        this.varietiesDialog.fields.product = variety.product;
        this.varietiesDialog.fields.percent = variety.percent;
      }

      this.varietiesDialog.open = true;
    },

    resetVarietyDialog() {
      this.varietiesDialog.open = false;
      this.varietiesDialog.isEditing = false;
      this.varietiesDialog.variety = {};
      this.varietiesDialog.fields.variety = "";
      this.varietiesDialog.fields.product = null;
      this.varietiesDialog.fields.percent = null;
    },

    saveVarietyDialog() {
      if (this.varietiesDialog.isEditing) {
        this.fields.breakdown[this.varietiesDialog.variety.index].variety =
          this.varietiesDialog.fields.variety;
        this.fields.breakdown[this.varietiesDialog.variety.index].product =
          this.varietiesDialog.fields.product;
        this.fields.breakdown[this.varietiesDialog.variety.index].percent =
          this.varietiesDialog.fields.percent;
      } else {
        this.fields.breakdown.push({
          variety: this.varietiesDialog.fields.variety,
          product: this.varietiesDialog.fields.product,
          percent: this.varietiesDialog.fields.percent,
        });
      }

      this.resetVarietyDialog();
    },

    deleteVariety(index) {
      this.fields.breakdown.splice(index, 1);
    },

    saveForm() {
      return this.fields;
    },
  },
};
</script>
