<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Products</h1>
        </v-col>
        <v-col cols="6" class="pl-12">
          <v-text-field
            label="Search Products"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-select
            label="Type"
            outlined
            dense
            :items="types"
            item-text="label"
            item-value="name"
            v-model="searchType"
            background-color="white"
            :clearable="true"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="warning"
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-grogreen-product-categories' }"
                >
                  <v-icon small>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>View Categories</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.productForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Product</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: 'module-grogreen-product-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Products</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="products"
        no-data-text="There are currently no products"
      >
        <template v-slot:item.categories="{ item }">
          {{ item.categories.map((c) => c.name).join(", ") }}
        </template>

        <template v-slot:item.type="{ item }">
          {{ item.type | capitalising }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                class="mr-2"
                color="green lighten-4 green--text"
                v-on="on"
                @click="$refs.tagsForm.openForm(item)"
              >
                <v-icon x-small>mdi-tag</v-icon>
              </v-btn>
            </template>
            <span>Add Tags</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.productForm.openForm(item.id)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteProduct(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <ProductForm ref="productForm" />
    <TagsForm ref="tagsForm" />
    <v-dialog v-model="deleteProduct.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Product</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteProduct.product.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteProduct"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteProduct.loading"
            @click="saveDeleteProduct"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductForm from "./components/ProductForm";
import TagsForm from "./components/TagsForm";

export default {
  components: {
    ProductForm,
    TagsForm,
  },

  data() {
    return {
      searchTerm: "",
      searchType: null,
      searchFormName: "",
      breadcrumbs: [
        {
          text: "groGreen",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Products",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Categories", value: "categories", sortable: false },
        { text: "Type", value: "type", sortable: false },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteProduct: {
        dialog: false,
        product: {},
        loading: false,
      },
      types: [
        { label: "Grasses", name: "grasses" },
        { label: "Wildflowers", name: "wildflowers" },
        { label: "Surfactants", name: "surfactants" },
        { label: "Fertilisers", name: "fertilisers" },
        { label: "Pesticides", name: "pesticides" },
        { label: "Equipment", name: "equipment" },
        { label: "Workwear", name: "workwear" },
        { label: "Other", name: "other" },
      ],
    };
  },

  filters: {
    capitalising: function (data) {
      var capitalized = [];
      data.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    },
  },

  computed: {
    products() {
      let products = this.$store.state.grogreen.products["products"];

      if (this.searchTerm !== "" || this.searchFormName !== "") {
        products = products.filter((p) => {
          const name = p.name.toLowerCase();
          const slug = p.slug.toLowerCase();
          const searchFormName = this.searchFormName;
          const type = p.type;
          return (
            (name.includes(this.searchTerm.toLowerCase()) ||
              slug.includes(this.searchTerm.toLowerCase())) &&
            type.includes(searchFormName)
          );
        });
      }

      if (this.searchType !== null) {
        products = products.filter((c) => {
          const type = c.type;
          return type.includes(this.searchType);
        });
      }

      return products;
    },
  },

  methods: {
    openDeleteProduct(product) {
      this.deleteProduct.product = product;
      this.deleteProduct.dialog = true;
    },

    resetDeleteProduct() {
      this.deleteProduct.dialog = false;
      this.deleteProduct.product = {};
      this.deleteProduct.loading = false;
    },

    saveDeleteProduct() {
      this.deleteProduct.loading = true;

      this.$store
        .dispatch("grogreen/products/deleteProduct", {
          appId: this.$route.params.id,
          productId: this.deleteProduct.product.id,
        })
        .then(() => {
          this.resetDeleteProduct();
        })
        .catch(() => {
          this.deleteProduct.loading = false;
        });
    },
  },
};
</script>
