<template>
  <div>
    <tiptap
      label="Dimensions"
      v-model="fields.dimensions"
      :error="errors.hasOwnProperty('dimensions')"
      :error-messages="errors['dimensions']"
    />

    <div
      class="v-text-field__details"
      v-if="errors['dimensions']"
      style="padding: 5px 12px"
    >
      <div class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div
            class="v-messages__message"
            v-for="error in errors['dimensions']"
            v-bind:key="error"
          >
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
  },
  props: {
    product: {
      required: true,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
  },

  data() {
    return {
      fields: {
        dimensions: null,
      },
      errors: {},
    };
  },

  mounted() {
    if (this.product !== null) {
      this.fields.dimensions = this.product.details.dimensions;
    }
  },

  methods: {
    saveForm() {
      return this.fields;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },
  },
};
</script>
