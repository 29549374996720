<template>
  <div>
    <tiptap
      class="mb-5"
      label="Active Ingredients"
      v-model="fields.active_ingredients"
    />
    <tiptap
      class="mb-5"
      label="Important Information"
      v-model="fields.important_information"
    />
  </div>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
  },
  props: {
    product: {
      required: true,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
  },

  data() {
    return {
      fields: {
        active_ingredients: null,
        important_information: null,
      },
      errors: {},
    };
  },

  mounted() {
    if (this.product !== null) {
      this.fields.important_information =
        this.product.details.important_information;
      this.fields.active_ingredients = this.product.details.active_ingredients;
    }
  },

  methods: {
    saveForm() {
      return this.fields;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },
  },
};
</script>
