<template>
  <div>
    <v-card elevation="2" class="mb-8">
      <v-card-subtitle>Sizes & Prices</v-card-subtitle>
      <v-divider></v-divider>
      <v-data-table
        :headers="sizesTable.headers"
        :items="pricesWithIndex"
        :disable-pagination="true"
        :hide-default-footer="true"
        no-data-text="There are currently no sizes/prices"
      >
        <template v-slot:foot>
          <div class="pl-4 pr-4">
            <v-btn color="accent" @click="openVarietyDialog()" small
              >Add New Size/Price</v-btn
            >
          </div>
        </template>
        <template v-slot:item.price="{ item }"> £{{ item.price }} </template>
        <template v-slot:item.is_default="{ item }">
          <v-chip label small color="success" v-if="item.is_default"
            >Yes</v-chip
          >
          <v-chip label small color="error" v-else>No</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                text
                icon
                color="blue"
                v-on="on"
                class="mr-2"
                @click="openVarietyDialog(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                text
                icon
                color="red"
                v-on="on"
                @click="deleteVariety(item.index)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-text class="pb-0">
        <v-switch
          label="Include VAT?"
          v-model="fields.include_vat"
          color="green"
          class="mt-1 mb-4"
          inset
          :error="errors.hasOwnProperty('include_vat')"
          :error-messages="errors['include_vat']"
        ></v-switch>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="priceDialog.open"
      max-width="400"
      @click:outside="resetVarietyDialog()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ priceDialog.isEditing ? "Edit" : "New" }} Size/Price
        </v-card-title>
        <v-card-text class="pt-6 pb-0">
          <v-text-field
            label="Name"
            v-model="priceDialog.fields.name"
            outlined
          ></v-text-field>
          <v-text-field
            label="Price"
            v-model="priceDialog.fields.price"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetVarietDialog()"
            >Close</v-btn
          >
          <v-btn color="accent" text type="submit" @click="saveVarietyDialog()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
    },
  },

  data() {
    return {
      fields: {
        prices: [],
      },
      errors: {},
      varietiesTable: {
        headers: [
          { text: "Variety", value: "variety", sortable: false },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "right",
          },
        ],
        dialog: {
          open: false,
          loading: false,
        },
      },
      sizesTable: {
        headers: [
          { text: "Name", value: "name", sortable: false },
          { text: "Price", value: "price", sortable: false },
          { text: "Is Default Price?", value: "is_default", sortable: false },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "right",
          },
        ],
        dialog: {
          open: false,
          loading: false,
        },
      },
      priceDialog: {
        open: false,
        isEditing: false,
        variety: {},
        fields: {
          name: null,
          price: null,
        },
      },
    };
  },

  mounted() {
    if (this.product == null) {
      this.fields.prices = [];
    }
  },

  computed: {
    pricesWithIndex() {
      return this.fields.prices.map((items, index) => ({
        ...items,
        index: index,
      }));
    },
  },

  methods: {
    openVarietyDialog(price = null) {
      if (price !== null) {
        this.priceDialog.isEditing = true;
        this.priceDialog.price = price;
        this.priceDialog.fields.name = price.name;
        this.priceDialog.fields.price = price.price;
      }

      this.priceDialog.open = true;
    },

    resetVarietyDialog() {
      this.priceDialog.open = false;
      this.priceDialog.isEditing = false;
      this.priceDialog.price = {};
      this.priceDialog.fields.price = null;
      this.priceDialog.fields.name = null;
    },

    saveVarietyDialog() {
      if (this.priceDialog.isEditing) {
        this.fields.prices[this.priceDialog.price.index].price =
          this.priceDialog.fields.price;

        this.fields.prices[this.priceDialog.price.index].name =
          this.priceDialog.fields.name;
      } else {
        this.fields.prices.push({
          price: this.priceDialog.fields.price,
          name: this.priceDialog.fields.name,
        });
      }

      this.resetVarietyDialog();
    },

    deleteVariety(index) {
      this.fields.prices.splice(index, 1);
    },

    saveForm() {
      return this.fields.prices;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },
  },
};
</script>
