<template>
  <div>
    <v-data-table
      :headers="varietiesTable.headers"
      :items="grassesWithIndex"
      :disable-pagination="true"
      :hide-default-footer="true"
      no-data-text="There are no varieties"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-subtitle-1"
            >Included Grasses</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn color="accent" small @click="openVarietyDialog()"
            >Add Row</v-btn
          >
        </v-toolbar>
        <v-alert
          type="error"
          dense
          col="4"
          rounded="0"
          class="mb-0"
          v-if="errors.hasOwnProperty('varieties')"
          >{{ errors["varieties"].join(", ") }}</v-alert
        >
        <v-divider></v-divider>
      </template>

      <template v-slot:item.variety="{ item }">
        <div style="white-space: pre-line" v-html="item.variety"></div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              text
              icon
              color="blue"
              v-on="on"
              class="mr-2"
              @click="openVarietyDialog(item)"
            >
              <v-icon x-small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              text
              icon
              color="red"
              v-on="on"
              @click="deleteVariety(item.index)"
            >
              <v-icon x-small>mdi-archive</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-data-table
      :headers="varietiesTable.headers"
      :items="wildflowersWithIndex"
      :disable-pagination="true"
      :hide-default-footer="true"
      no-data-text="There are no varieties"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-subtitle-1"
            >Included Wildflowers</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn color="accent" small @click="openVarietyWildflowerDialog()"
            >Add Row</v-btn
          >
        </v-toolbar>
        <v-alert
          type="error"
          dense
          col="4"
          rounded="0"
          class="mb-0"
          v-if="errors.hasOwnProperty('varieties')"
          >{{ errors["varieties"].join(", ") }}</v-alert
        >
        <v-divider></v-divider>
      </template>

      <template v-slot:item.variety="{ item }">
        <div style="white-space: pre-line" v-html="item.variety"></div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              text
              icon
              color="blue"
              v-on="on"
              class="mr-2"
              @click="openVarietyWildflowerDialog(item)"
            >
              <v-icon x-small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              text
              icon
              color="red"
              v-on="on"
              @click="deleteWildflowerVariety(item.index)"
            >
              <v-icon x-small>mdi-archive</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog
      v-model="varietiesWildflowerDialog.open"
      max-width="400"
      @click:outside="resetVarietyWildflowerDialog()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ varietiesWildflowerDialog.isEditing ? "Edit" : "New" }} Included
          Wildflower</v-card-title
        >
        <v-card-text class="pt-6 pb-0">
          <v-text-field
            label="Variety *"
            v-model="varietiesWildflowerDialog.fields.variety"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetVarietyWildflowerDialog()"
            >Close</v-btn
          >
          <v-btn
            color="accent"
            text
            type="submit"
            @click="saveVarietyWildflowerDialog()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="varietiesDialog.open"
      max-width="400"
      @click:outside="resetVarietyDialog()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ varietiesDialog.isEditing ? "Edit" : "New" }} Included
          Wildflower</v-card-title
        >
        <v-card-text class="pt-6 pb-0">
          <v-text-field
            label="Variety *"
            v-model="varietiesDialog.fields.variety"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetVarietDialog()"
            >Close</v-btn
          >
          <v-btn color="accent" text type="submit" @click="saveVarietyDialog()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      fields: {
        included_grasses: [],
        included_wildflowers: [],
      },
      errors: {},
      varietiesTable: {
        headers: [
          { text: "Variety", value: "variety", sortable: false },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "right",
          },
        ],
        dialog: {
          open: false,
          loading: false,
        },
      },
      varietiesDialog: {
        open: false,
        isEditing: false,
        variety: {},
        fields: {
          variety: null,
        },
      },

      varietiesWildflowerDialog: {
        open: false,
        isEditing: false,
        variety: {},
        fields: {
          variety: null,
        },
      },
    };
  },

  mounted() {
    if (this.product !== null) {
      if (this.product.details !== null) {
        if (this.product.details.included_grasses) {
          this.fields.included_grasses = this.product.details.included_grasses;
        } else {
          this.fields.included_grasses = [];
        }

        if (this.product.details.included_wildflowers) {
          this.fields.included_wildflowers =
            this.product.details.included_wildflowers;
        } else {
          this.fields.included_wildflowers = [];
        }
      }
    }
  },

  computed: {
    grassesWithIndex() {
      return this.fields.included_grasses.map((variety, index) => ({
        ...variety,
        index,
      }));
    },

    wildflowersWithIndex() {
      return this.fields.included_wildflowers.map((variety, index) => ({
        ...variety,
        index,
      }));
    },
  },

  methods: {
    openVarietyDialog(variety = null) {
      if (variety !== null) {
        this.varietiesDialog.isEditing = true;
        this.varietiesDialog.variety = variety;
        this.varietiesDialog.fields.variety = variety.variety;
      }

      this.varietiesDialog.open = true;
    },

    openVarietyWildflowerDialog(variety = null) {
      if (variety !== null) {
        this.varietiesWildflowerDialog.isEditing = true;
        this.varietiesWildflowerDialog.variety = variety;
        this.varietiesWildflowerDialog.fields.variety = variety.variety;
      }

      this.varietiesWildflowerDialog.open = true;
    },

    resetVarietyDialog() {
      this.varietiesDialog.open = false;
      this.varietiesDialog.isEditing = false;
      this.varietiesDialog.variety = {};
      this.varietiesDialog.fields.variety = null;
    },

    resetVarietyWildflowerDialog() {
      this.varietiesWildflowerDialog.open = false;
      this.varietiesWildflowerDialog.isEditing = false;
      this.varietiesWildflowerDialog.variety = {};
      this.varietiesWildflowerDialog.fields.variety = null;
    },

    saveVarietyDialog() {
      if (this.varietiesDialog.isEditing) {
        this.fields.included_grasses[
          this.varietiesDialog.variety.index
        ].variety = this.varietiesDialog.fields.variety;
      } else {
        this.fields.included_grasses.push({
          variety: this.varietiesDialog.fields.variety,
        });
      }

      this.resetVarietyDialog();
    },

    saveVarietyWildflowerDialog() {
      if (this.varietiesWildflowerDialog.isEditing) {
        this.fields.included_wildflowers[
          this.varietiesWildflowerDialog.variety.index
        ].variety = this.varietiesWildflowerDialog.fields.variety;
      } else {
        this.fields.included_wildflowers.push({
          variety: this.varietiesWildflowerDialog.fields.variety,
        });
      }

      this.resetVarietyWildflowerDialog();
    },

    deleteVariety(index) {
      this.fields.included_grasses.splice(index, 1);
    },

    deleteWildflowerVariety(index) {
      this.fields.included_wildflowers.splice(index, 1);
    },

    saveForm() {
      return this.fields;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },
  },
};
</script>
